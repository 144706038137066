import Page from '../../classes/Page';
import { calendarSlider } from '../../components/calendarSlider';
import { eventsSliders } from './eventsSliders';
// import { homeSliders } from './homeSliders';

const eventsPage = new Page({
  name: 'events',
  rootElementId: 'js-page-events',

  onCreate() {
    console.log('events create')
  },
  onInit() {
    console.log('events init')

    this
      .addComponent(eventsSliders)
      .addComponent(calendarSlider)
  },
  onDestroy() {
    console.log('events destroy')
  },
});

export default eventsPage;
