import 'svgxuse';
import regularPage from './pages/regular';
import homePage from "./pages/home";

import {$dom} from './helpers/dom';

import {isElement} from './helpers/_utilities';
import questionsPage from './pages/questions';
import votePage from './pages/vote';
import eventsPage from './pages/events';
import cityPage from './pages/city';
import roomsPage from './pages/rooms';
import eventsDetailPage from './pages/events-detail';
import newsPage from './pages/news-page';

const pages = [
	regularPage,
	homePage,
	questionsPage,
	votePage,
	eventsPage,
	cityPage,
	roomsPage,
	eventsDetailPage,
	newsPage
];

$dom.ready(function() {
	this.currentPage = pages.find(page => isElement(page.rootEl));

	if (Boolean(this.currentPage)) this.currentPage.init()
});
