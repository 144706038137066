import Swiper from 'swiper';
import Component from '../classes/Component';
import { $events } from '../helpers/events';
import { $dom } from '../helpers/dom';
import variables from '../variables';

const { get, getAll, addClass, removeClass, hasClass, attr, append, remove } = $dom;
const { active, current, visible } = variables.classNames;

const calendarSelector = '.js-horizontal-calendar';
const calendarWrapperSelector = '.js-horizontal-calendar-wrapper';
const calendaritemSelector = '.js-horizontal-calendar-item';
const calendarDateSelector = '.js-horizontal-calendar-date';

const calendarButtonPrevSelector = '.js-horizontal-calendar-button-prev';
const calendarButtonNextSelector = '.js-horizontal-calendar-button-next';

const preloaderBlockSelector = '.js-preloader';
const preloaderLoadingBlocksSelector = '.js-preloader-loading-blocks';

const dataDate = 'data-date';
const currentDataDate = 'data-current-date';

export function calendarSlider() {

  let mouseDown = false;
  let mouseUp = true;
  let isNotDragging = false;
  let startX, scrollLeft;
  let currentElement = null;
  let nextElement = null;

  let month = null;
  let year = null;

  const slider = get(calendarWrapperSelector);

  function getMonthName(index) {
    return ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль',
      'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'][index];
  }

  function getDaysInMonth(month, year) {
    let date = new Date(Date.UTC(year, month, 1));
    let days = [];
    while (date.getUTCMonth() === month) {
      days.push(new Date(date));
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
  }

  function printCalendar() {
    const currentDate = attr(calendarSelector, dataDate);
    const choosenDate = attr(calendarSelector, currentDataDate);

    const selectedDate = currentDate.split('.');

    console.log(currentDate.split('.'));

    let choosenDateDay = null;
    let choosenDateMonth = null;
    let choosenDateYear = null;

    if (choosenDate) {

      choosenDateDay = choosenDate.split('.')[1];
      choosenDateMonth = choosenDate.split('.')[0];
      choosenDateYear = choosenDate.split('.')[2];
    }

    const [month, date1, year] = selectedDate
    let date = new Date(year, month - 1, date1)

    // let date = new Date(selectetDate);
    let monthList = [];
    let text = '';

    let currentDay = parseInt(date.toDateString().split(' ')[2]);
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();

    let nextMonth = currentMonth + 1;
    let nextYear = currentYear;

    monthList.push(currentMonth);

    if (nextMonth > 12) {
      nextMonth = 1;
      nextYear += 1;

      const nextMonthDate = new Date(nextYear, nextMonth, 1);

      monthList.push(nextMonthDate.getMonth() + 1);
    } else {
      monthList.push(nextMonth);
    }

    console.log(getMonthName(nextMonth - 1));

    monthList.forEach((element) => {

      if (element == currentMonth) {
        text += `<div class="calendar__item js-horizontal-calendar-item"><span class="calendar__item-month">${getMonthName(element - 1)}</span>`
      } else {
        text += `<div class="calendar__item js-horizontal-calendar-item"><span class="calendar__item-month">${getMonthName(nextMonth - 1)}</span>`
      }

      text += `<div class="calendar__item-list">`

      if (element == currentMonth) {

        getDaysInMonth(currentMonth - 1, currentYear).forEach((element, i) => {

          if (i + 1 < currentDay) return

          text += `<span class="calendar__item-date js-horizontal-calendar-date" data-month='${currentMonth}' data-date='${currentDay}' data-year='${currentYear}'>${currentDay}</span>`;
          currentDay++;
        });
      } else if (element != currentMonth && currentYear == nextYear) {

        getDaysInMonth(nextMonth - 1, currentYear).forEach((element, i) => {
          text += `<span class="calendar__item-date js-horizontal-calendar-date" data-month='${nextMonth}' data-date='${i + 1}' data-year='${currentYear}'>${i + 1}</span>`
        });
      } else {

        getDaysInMonth(nextMonth - 1, nextYear).forEach((element, i) => {
          text += `<span class="calendar__item-date js-horizontal-calendar-date" data-month='${nextMonth}' data-date='${i + 1}' data-year='${nextYear}'>${i + 1}</span>`
        });
      }

      text += '</div>'

      text += '</div>'
    });

    get(calendarWrapperSelector).innerHTML = text;

    if (!choosenDate) return

    getAll(calendarDateSelector).forEach(element => {

      if (attr(element, 'data-year') == choosenDateYear && attr(element, 'data-month') == choosenDateMonth && attr(element, 'data-date') == choosenDateDay) {
        addClass(element, active);
      }
    })
  }

  function checkActiveElements() {

    const activeItem = get(`${calendaritemSelector} .is-active`);

    if (!activeItem) return;

    scrollToStart(activeItem);
  }

  const clearClasses = () => {
    const dateList = getAll(calendarDateSelector);

    dateList.forEach(element => {
      removeClass(element, active);
    });
  }

  const scrollToStart = element => {

    const buttonWidth = get(calendarButtonPrevSelector).offsetWidth;

    get(calendarWrapperSelector).scrollTo({
      left: element.offsetLeft - buttonWidth * 2,
      behavior: "smooth"
    });
  }

  const clickHandler = event => {
    const target = event.target.closest(calendarDateSelector);

    if (!event.target.closest(calendarDateSelector)) return;

    getAll(calendaritemSelector).forEach(element => {
      removeClass(element, current)
    })

    addClass(target.parentNode.parentNode, current)

    clearClasses();

    addClass(target, active);

    scrollToStart(target);

    const day = target.innerHTML;

    let currentDate = `${attr(target, 'data-month')}.${day}.${attr(target, 'data-year')}`;

    attr(calendarSelector, currentDataDate, currentDate);

    // console.log(chosenDate);

    // const sliderRoot = get('.js-events-intro-slider');
    // const sliderWrapper = get('.swiper-wrapper', sliderRoot);

    // const sliderSlide = sliderWrapper.innerHTML;

    // ------ ajax here ----------

    $.ajax({
      url: '/events/', // insert you file name
      type: 'POST',
      dataType: 'html',
      data: { date: currentDate },

      success: function (data) {
        //console.log(data);
        document.querySelector('#js-ajax-slider').innerHTML = data;
        currentPage.components.lazyLoad.init();

        currentPage.components.eventsSliders.eventsSlider.update();
      }
    });

    // ---------------------------
  }

  const scrollToMonth = event => {
    const target = event.target;

    if (target.closest(calendarButtonPrevSelector)) {

      get(calendarWrapperSelector).scrollLeft -= 300;

      return;
    }

    if (target.closest(calendarButtonNextSelector)) {

      get(calendarWrapperSelector).scrollLeft += 300;

      return;
    }
  }

  return new Component({
    name: 'calendarSlider',
    requiredTargets: calendarSelector,
    onCreate() { },
    onInit() {

      printCalendar();

      checkActiveElements();

      $events.delegate
        .on('click', calendarSelector, scrollToMonth)
        .on('click', calendaritemSelector, clickHandler)
    },
    onDestroy() {
      $events.delegate
        .off('click', calendarSelector, scrollToMonth)
        .off('click', calendarDateSelector, clickHandler)
    }
  })
}