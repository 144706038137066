import Swiper from 'swiper';
import Component from '../../classes/Component';
import { Navigation } from 'swiper/modules';

const eventsRootId = 'js-page-events'
const eventsIntroSliderSelector = '.js-events-intro-slider';
// const newsSliderSelector = '.js-news-slider';

export function eventsSliders() {
  return new Component({
    name: 'eventsSliders',
    requiredTargets: `#${eventsRootId}`,
    onCreate() {
    },
    onInit() {
      this.eventsSlider = new Swiper(eventsIntroSliderSelector, {
        modules: [Navigation],

        slidesPerView: 2,
        spaceBetween: 22,

        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 22,
          },
        },

        navigation: {
          nextEl: '.js-events-intro-button-next',
          prevEl: '.js-events-intro-button-prev',
          disabledClass: 'events-intro__slider-button-disabled',
        },

      })

    },
    onDestroy() {
      this.eventsSlider = null;
    }
  })
}