import Component from '../classes/Component';
import { $dom } from '../helpers/dom';
import { $events } from '../helpers/events';
import variables from '../variables';

const { active } = variables.classNames

const { get, getAll, addClass, removeClass, toggleClass } = $dom;

const collapeSelector = '.js-collape-block';
const collapeButtonSelector = '.js-collape-block-button';
const collapeContentSelector = '.js-collape-block-content';

export function collapseFunctional() {

  const clearClasses = () => {
    getAll(collapeSelector).forEach(element => {
      removeClass(element, active);
    });
  }

  const collapseClickHandler = event => {
    const target = event.target.closest(collapeSelector);

    // clearClasses();

    const button = get(collapeButtonSelector, target);
    const content = get(collapeContentSelector, target);

    toggleClass(target, active);

    console.log(target);
  }

  return new Component({
    name: 'collapseFunctional',
    requiredTargets: collapeSelector,
    onCreate() { },
    onInit() {
      $events.delegate
        .on('click', collapeSelector, collapseClickHandler)
    },
    onDestroy() {
      $events.delegate
        .off('click', collapeSelector, collapseClickHandler)
    }
  })
}