import Page from '../../classes/Page';
import { voteSlider } from './voteSlider';

const votePage = new Page({
  name: 'vote',
  rootElementId: 'js-page-vote',

  onCreate() {
    console.log('vote create')
  },
  onInit() {
    console.log('vote init')

    this
    // .addComponent(voteSlider)
  },
  onDestroy() {
    console.log('vote destroy')
  },
});

export default votePage;
