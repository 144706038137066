import Page from '../../classes/Page';
import { roomsContentFunctional } from './roomsContentFunctional';

const roomsPage = new Page({
  name: 'rooms',
  rootElementId: 'js-page-rooms',

  onCreate() {
    console.log('rooms create')
  },
  onInit() {
    console.log('rooms init')

    this
    .addComponent(roomsContentFunctional)
  },
  onDestroy() {
    console.log('rooms destroy')
  },
});

export default roomsPage;
