import Swiper from 'swiper';
import Component from '../../classes/Component';

const voteSliderSelector = '.js-vote-slider';

export function voteSlider() {
  return new Component({
    name: 'voteSlider',
    requiredTargets: voteSliderSelector,
    onCreate() { },
    onInit() {
      this.voteSlider = new Swiper(voteSliderSelector, {
        slidesPerView: 5,
        spaceBetween: 15,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          // 991: {
          //   slidesPerView: 3,
          // },
          1200: {
            slidesPerView: 4,
          },
          1440: {
            slidesPerView: 5,
          },
        }
      })
     },
    onDestroy() { 
      this.voteSlider = null;
    }
  })
}