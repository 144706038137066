import { Navigation } from 'swiper/modules';
import Component from '../../classes/Component';
import Swiper from 'swiper';

const newsSliderSelector = '.js-news-slider';

export function newsSlider() {
  return new Component({
    name: 'newsSlider',
    requiredTargets: newsSliderSelector,
    onCreate() { },
    onInit() {

      this.eventsSlider = new Swiper(newsSliderSelector, {
        modules: [Navigation],

        slidesPerView: 1,
        spaceBetween: 10,

        navigation: {
          nextEl: '.js-events-button-next',
          prevEl: '.js-events-button-prev',
          disabledClass: 'info__slider-button-disabled',
        },
      })
    },
    onDestroy() {
      this.eventsSlider = null;
    }
  })
}