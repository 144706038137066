import Swiper from 'swiper';
import Component from '../../classes/Component';
import { Navigation } from 'swiper/modules';

const citySliderSelector = '.js-city-slider';

export function citySliders() {
  return new Component({
    name: 'citySliders',
    requiredTargets: citySliderSelector,
    onCreate() { },
    onInit() {
      this.citySlider = new Swiper(citySliderSelector, {
        modules: [Navigation],

        navigation: {
          nextEl: '.js-city-button-next',
          prevEl: '.js-city-button-prev',
          disabledClass: 'disabled',
        },
      })
     },
    onDestroy() { }
  })
}