import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';

const { get, getAll, attr, addClass, removeClass, hasClass, toggleClass } = $dom;
const { active } = variables.classNames

const contentRoot = '.js-rooms';
const tabsSelector = '.js-rooms-tabs';
const tabsItemSelector = '.js-rooms-tabs-item';
const contentSelector = '.js-rooms-content';
const contentitemSelector = '.js-rooms-content-item';
const buttonsBlockSelector = '.js-rooms-buttons';

const buttonsPrevSelector = '.js-rooms-buttons-prev';
const buttonsNextSelector = '.js-rooms-buttons-next';

const roomsSelectButtonSelector = '.js-rooms-select-button';

const playerSelector = '.js-player';
const playerFileSelector = '.js-player-file';

const dataType = 'data-type';
const dataIndex = 'data-index';
const dataName = 'data-name';

export function roomsContentFunctional() {

  const tabsList = getAll(tabsItemSelector, contentRoot);
  const contentList = getAll(contentitemSelector, contentRoot);

  let prevTabIndex = null;
  let prevTabElement = null;
  let prevContentElement = null;

  let nextTabIndex = null;
  let nextTabElement = null;
  let nextContentElement = null;

  let audio = null;

  const clearClasses = () => {

    tabsList.forEach((element, i) => {
      removeClass(element, active);
      removeClass(contentList[i], active);
    });
  }

  const tabClickHandler = event => {
    const target = event.target.closest(tabsItemSelector);

    if (!target) return

    const tabRoot = get(tabsSelector);

    const type = attr(target, dataType);

    clearClasses();
    stopAudio();

    addClass(target, active);
    addClass(get(`${contentitemSelector}[${dataType}=${type}]`), active);

    removeClass(tabRoot, active);

    changeButtonsText(getCurrentIndex());
    changeSelectText();
  }

  const selectClickHandler = event => {
    const target = event.target.closest(roomsSelectButtonSelector);

    toggleClass(target.parentNode, active);
  }

  function changeSelectText() {

    get('span', getCurrentItem())

    get('span', roomsSelectButtonSelector).innerHTML = get('span', getCurrentItem()).innerHTML;
  }

  function getCurrentItem() {
    return get(`${tabsItemSelector}.${active}`);
  }

  function getCurrentIndex() {
    return Number(attr(get(`${tabsItemSelector}.${active}`), dataIndex));
  }

  function defineItems(currentIndex) {

    if (currentIndex > 1) {
      prevTabIndex = Number(attr(get(`${tabsItemSelector}[data-index='${currentIndex - 1}']`), dataIndex));
      prevTabElement = get(`${tabsItemSelector}[data-index='${currentIndex - 1}']`);
      prevContentElement = get(`${contentitemSelector}[data-index='${currentIndex - 1}']`);
    } else {
      prevTabIndex = Number(attr(get(`${tabsItemSelector}[data-index='${tabsList.length}']`), dataIndex));
      prevTabElement = get(`${tabsItemSelector}[data-index='${tabsList.length}']`);
      prevContentElement = get(`${contentitemSelector}[data-index='${tabsList.length}']`);
    }

    if (currentIndex + 1 < tabsList.length) {
      nextTabIndex = Number(attr(get(`${tabsItemSelector}[data-index='${currentIndex + 1}']`), dataIndex));
      nextTabElement = get(`${tabsItemSelector}[data-index='${currentIndex + 1}']`);
      nextContentElement = get(`${contentitemSelector}[data-index='${currentIndex + 1}']`);
    } else if (currentIndex + 1 == tabsList.length) {
      nextTabIndex = Number(attr(get(`${tabsItemSelector}[data-index='${tabsList.length}']`), dataIndex));
      nextTabElement = get(`${tabsItemSelector}[data-index='${tabsList.length}']`);
      nextContentElement = get(`${contentitemSelector}[data-index='${tabsList.length}']`);
    } else {
      nextTabIndex = Number(attr(get(`${tabsItemSelector}[data-index='1']`), dataIndex));
      nextTabElement = get(`${tabsItemSelector}[data-index='1']`);
      nextContentElement = get(`${contentitemSelector}[data-index='1']`);
    }
  }

  function changeButtonsText(currentIndex) {
    defineItems(currentIndex);

    const buttonPrevText = get(`${buttonsPrevSelector} span`);
    const buttonNextText = get(`${buttonsNextSelector} span`);

    buttonPrevText.innerHTML = `← Назад в ${attr(prevTabElement, dataName)}`;
    buttonNextText.innerHTML = `Далее в ${attr(nextTabElement, dataName)} →`;
  }

  const buttonHandler = event => {
    const target = event.target;

    if (target.closest(buttonsPrevSelector)) {

      const currentTabElement = get(`${tabsItemSelector}.${active}`)
      const currentContentElement = get(`${contentitemSelector}.${active}`)

      const currentIndex = getCurrentIndex();

      stopAudio();

      defineItems(currentIndex);

      removeClass(currentTabElement, active);
      removeClass(currentContentElement, active);

      addClass(prevTabElement, active);
      addClass(prevContentElement, active);

      changeButtonsText(getCurrentIndex());

      return
    }

    if (target.closest(buttonsNextSelector)) {

      const currentTabElement = get(`${tabsItemSelector}.${active}`)
      const currentContentElement = get(`${contentitemSelector}.${active}`)

      const currentIndex = getCurrentIndex();

      stopAudio();

      defineItems(currentIndex);

      removeClass(currentTabElement, active);
      removeClass(currentContentElement, active);

      addClass(nextTabElement, active);
      addClass(nextContentElement, active);

      changeButtonsText(getCurrentIndex());

      return
    }
  }

  const playerHandler = event => {
    const target = event.target.closest(playerSelector);
    const audioFile = attr(get(playerFileSelector, target), 'src');

    if(audio) {
      stopAudio();
      audio = null;
      return;
    }

    audio = new Audio(audioFile);

    audio.play();
  }

  function stopAudio() {
    if (!audio) return

    audio.pause();
    audio.currentTime = 0;
  }

  return new Component({
    name: 'roomsContentFunctional',
    requiredTargets: contentRoot,
    onCreate() { },
    onInit() {

      currentPage.components.lazyLoad.lazyInstance.loadAll();

      defineItems(getCurrentIndex());

      changeButtonsText(getCurrentIndex());
      changeSelectText();

      $events.delegate
        .on('click', tabsSelector, tabClickHandler)
        .on('click', roomsSelectButtonSelector, selectClickHandler)
        .on('click', buttonsBlockSelector, buttonHandler)
        .on('click', playerSelector, playerHandler)
    },
    onDestroy() {
      $events.delegate
        .off('click', tabsSelector, tabClickHandler)
        .off('click', roomsSelectButtonSelector, selectClickHandler)
        .off('click', buttonsBlockSelector, buttonHandler)
        .off('click', playerSelector, playerHandler)
    }
  })
}