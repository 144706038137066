import Page from '../../classes/Page';
import { homeSliders } from './homeSliders';

const homePage = new Page({
  name: 'home',
  rootElementId: 'js-page-home',

  onCreate() {
    console.log('home create')
  },
  onInit() {
    console.log('home init')

    this
      .addComponent(homeSliders)
  },
  onDestroy() {
    console.log('home destroy')
  },
});

export default homePage;
