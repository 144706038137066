import Component from '../classes/Component';

const rootSelector = '.js-newcomer';

export function newcomerFunctional() {
  return new Component({
    name: 'newcomerFunctional',
    requiredTargets: rootSelector,
    onCreate() { },
    onInit() {
      return ;
      if(!localStorage.getItem('isFirstTime')) {
        localStorage.setItem("isFirstTime", true);

        currentPage.components.modal.open('js-newcomer-modal');
      }

     },
    onDestroy() { }
  })
}