import Component from '../../classes/Component';
import { $dom } from '../../helpers/dom';
import { $events } from '../../helpers/events';
import variables from '../../variables';

const { get, getAll, addClass, removeClass, attr } = $dom;
const { active } = variables.classNames;

const preloaderBlockSelector = '.js-preloader-block';
const preloaderItemSelector = '.js-preloader-item';

const dataVote = 'data-vote';
const dataId = 'data-id';

export function cityPreloader() {

  const voteClickHandler = event => {
    const target = event.target.closest(preloaderBlockSelector);

    const id = attr(target, dataId);
    const vote = attr(target, dataVote);

    const buttons = getAll(preloaderBlockSelector);

    console.log(buttons);

    if (id && vote) {
      event.preventDefault();

      currentPage.components.cityPreloader.preloaderVisibility(true);

      $.ajax({
        url: '/ajax/index.php',
        action: 'vote',
        type: 'POST',
        dataType: 'html',
        data: {
          action: 'vote',
          voteId: vote,
        },

        success: function (data) {

          data = JSON.parse(data);
          if (data.success) {
            //убрать перезагрузку страницы, выдать попап об успехе. поменять на ВСЕХ кнопках голосования надписать "голосовать" на "голос принят" и заблочить клик по ним
            // window.location.reload();
            
            currentPage.components.modal.open('js-example-success-vote');

            buttons.forEach(element => {
              console.log(get('span', element).innerHTML);
              get('span', element).innerHTML = 'Голос принят';
              addClass(element, 'disabled');
            })

          } else {
            //показать попап с ошибкой
            // alert('error');
            currentPage.components.modal.open('js-example-failed-vote');
          }
          currentPage.components.cityPreloader.preloaderVisibility(false);
        }
      });

      return
    }
  }

  return new Component({
    name: 'cityPreloader',
    requiredTargets: preloaderBlockSelector,
    onCreate() { },
    onInit() {
      this.preloaderVisibility = (isVisible) => {

        if (isVisible) {
          getAll(preloaderBlockSelector).forEach(element => {
            addClass(element, active);
          });
        } else {
          getAll(preloaderBlockSelector).forEach(element => {
            removeClass(element, active);
          });
        }
      }

      $events.delegate
        .on('click', preloaderBlockSelector, voteClickHandler)
    },
    onDestroy() { }
  })
}