import Page from '../../classes/Page';
import { calendarSlider } from '../../components/calendarSlider';
// import { homeSliders } from './homeSliders';

const eventsDetailPage = new Page({
  name: 'eventsDetail',
  rootElementId: 'js-page-events-detail',

  onCreate() {
    console.log('eventsDetail create')
  },
  onInit() {
    console.log('eventsDetail init')

    this
      // .addComponent(eventsDetailSliders)
      .addComponent(calendarSlider)
  },
  onDestroy() {
    console.log('eventsDetail destroy')
  },
});

export default eventsDetailPage;
