import Swiper from 'swiper';
import Component from '../../classes/Component';
import { Navigation } from 'swiper/modules';

const homeRootId = 'js-page-home'
const eventsSliderSelector = '.js-events-slider';
const newsSliderSelector = '.js-news-slider';

export function homeSliders() {
  return new Component({
    name: 'homeSliders',
    requiredTargets: `#${homeRootId}`,
    onCreate() {
    },
    onInit() {
      this.eventsSlider = new Swiper(eventsSliderSelector, {
        modules: [Navigation],

        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          567: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        },

        navigation: {
          nextEl: '.js-events-button-next',
          prevEl: '.js-events-button-prev',
          disabledClass: 'info__slider-button-disabled',
        },
        // navigation: {
        //   nextEl: '.js-news-button-prev',
        //   prevEl: '.js-news-button-next',
        //   disabledClass: 'info__slider-button-disabled',
        // },

      })

      this.newsSlider = new Swiper(newsSliderSelector, {
        modules: [Navigation],

        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          567: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        },

        navigation: {
          nextEl: '.js-news-button-next',
          prevEl: '.js-news-button-prev',
          disabledClass: 'info__slider-button-disabled',
        },
      })
    },
    onDestroy() {
      this.eventsSlider = null;
      this.newsSlider = null;
    }
  })
}