import Page from '../../classes/Page';
// import { homeSliders } from './homeSliders';

const questionsPage = new Page({
  name: 'questions',
  rootElementId: 'js-page-questions',

  onCreate() {
    console.log('questions create')
  },
  onInit() {
    console.log('questions init')

    this
      // .addComponent(questionsSliders)
  },
  onDestroy() {
    console.log('questions destroy')
  },
});

export default questionsPage;
