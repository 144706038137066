import Page from '../../classes/Page';
import { cityPreloader } from './cityPreloader';
import { citySliders } from './citySliders';

const cityPage = new Page({
  name: 'city',
  rootElementId: 'js-page-city',

  onCreate() {
    console.log('city create')
  },
  onInit() {
    console.log('city init')

    this
      .addComponent(citySliders)
      .addComponent(cityPreloader)
  },
  onDestroy() {
    console.log('city destroy')
  },
});

export default cityPage;
