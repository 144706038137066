import Page from '../../classes/Page';
import { newsSlider } from './newsSlider';

const newsPage = new Page({
  name: 'news',
  rootElementId: 'js-page-news',

  onCreate() {
    console.log('news create')
  },
  onInit() {
    console.log('news init')

    this
      .addComponent(newsSlider)
  },
  onDestroy() {
    console.log('news destroy')
  },
});

export default newsPage;
